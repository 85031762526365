/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import { Card, Typography } from "@mui/material";
import SuiBox from "components/SuiBox";

// Soft UI Dashboard React components
// import MasterCard from "examples/Cards/MasterCard";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import BallSvg from "layouts/financial-analysis/BallSvg";

// Billing page components
// import PaymentMethod from "layouts/billing/components/PaymentMethod";
// import Invoices from "layouts/billing/components/Invoices";
// import BillingInformation from "layouts/billing/components/BillingInformation";
// import Transactions from "layouts/billing/components/Transactions";

function FinancialAnalysis() {
  return (
    <DashboardLayout>
      <SuiBox mt={4}>
        <Card style={{ paddingTop: "20px" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <BallSvg style={{ height: "20px" }} />
            <Typography variant="h2" gutterBottom style={{ paddingTop: "20px" }}>
              Lauching soon!
            </Typography>
          </div>
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

export default FinancialAnalysis;
