function BallSvg() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="200pt"
      height="200pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="rgb(52, 71, 103)"
        stroke="none"
      >
        <path
          d="M3015 5114 c-526 -43 -1024 -301 -1350 -699 -170 -208 -280 -407
-358 -650 -71 -219 -92 -362 -91 -615 0 -206 10 -286 60 -484 l25 -100 -530
-530 c-293 -294 -535 -544 -541 -559 -5 -16 -7 -39 -4 -53 10 -37 60 -74 102
-74 34 0 60 24 543 506 l506 506 53 -99 c241 -447 634 -787 1093 -944 37 -12
67 -25 67 -28 0 -4 -149 -155 -331 -336 -182 -182 -344 -348 -360 -369 -39
-52 -39 -98 0 -137 23 -23 38 -29 72 -29 l43 0 410 409 411 408 105 -13 c796
-97 1585 322 1954 1037 157 303 220 559 220 894 0 343 -60 598 -209 891 -138
272 -313 483 -555 671 -253 197 -588 336 -915 383 -86 12 -339 20 -420 14z
m889 -360 c155 -71 367 -217 469 -321 l47 -49 -40 -354 c-22 -195 -44 -366
-48 -379 -7 -23 -25 -28 -285 -86 l-279 -63 -249 181 -249 182 2 275 3 274
260 188 c143 103 267 187 276 188 9 0 51 -16 93 -36z m-1142 -166 l298 -182 0
-271 0 -270 -247 -180 c-151 -109 -255 -178 -268 -177 -25 2 -546 120 -550
124 -1 2 -28 164 -59 362 l-58 359 64 64 c63 65 184 163 268 218 40 27 239
134 249 135 2 0 138 -82 303 -182z m-257 -1281 c5 -6 185 -567 185 -576 0 -6
-79 -132 -176 -281 -153 -235 -180 -271 -198 -266 -12 3 -165 32 -341 66 -176
33 -327 63 -336 66 -42 12 -165 343 -199 533 l-13 72 257 258 257 257 281 -63
c155 -35 282 -64 283 -66z m2167 -159 l229 -237 -16 -87 c-30 -161 -163 -514
-193 -514 -5 0 -164 -29 -353 -65 -268 -50 -346 -62 -355 -52 -24 26 -344 527
-344 538 0 11 179 571 184 576 2 2 128 32 282 66 l279 64 28 -26 c16 -14 132
-133 259 -263z m-1028 -800 c93 -144 171 -269 173 -278 2 -8 -77 -154 -176
-325 l-179 -310 -89 -12 c-113 -15 -303 -15 -417 0 l-88 12 -183 315 -182 315
36 56 c20 31 99 154 176 273 l140 216 310 -1 310 0 169 -261z"
        />
        <path
          d="M1405 1534 c-33 -17 -1330 -1306 -1376 -1368 -39 -53 -38 -89 5 -132
43 -43 79 -44 132 -5 21 16 339 330 705 697 454 456 669 678 673 696 10 43
-17 96 -58 113 -43 18 -44 18 -81 -1z"
        />
        <path
          d="M1625 301 c-61 -25 -80 -108 -36 -155 79 -84 207 4 159 109 -20 44
-76 65 -123 46z"
        />
      </g>
    </svg>
  );
}

export default BallSvg;
