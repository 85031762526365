import axios from "./index";

class SoccerApi {
  static getSquad = () => {
    return axios.get(`squad`);
  };
  static getPlayerTraits = (playerId) => {
    return axios.get(`players/${playerId}/traits`);
  };
}

export default SoccerApi;
