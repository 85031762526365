/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiBadge from "components/SuiBadge";
import SuiAvatar from "components/SuiAvatar";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Table";

// Custom styles for the Tables
import styles from "layouts/performance-analysis/styles";

// Data
// import projectsTableData from "layouts/tables/data/projectsTableData";
import SoccerApi from "api/soccer";
import { useEffect, useState } from "react";
import { CircularProgress, Link } from "@mui/material";
import PerformanceDrawer from "./components/PerformanceDrawer";

function Tables() {
  const classes = styles();
  const [players, setPlayers] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [performanceDrawerOpen, setPerformanceDrawerOpen] = useState(false);
  // const { columns, rows } = authorsTableData;
  // const { columns: prCols, rows: prRows } = projectsTableData;

  const playerColumns = [
    { name: "player", align: "left" },
    { name: "position", align: "left" },
    { name: "country", align: "center" },
    { name: "goals", align: "center" },
    { name: "assists", align: "center" },
    { name: "rating", align: "center" },
    { name: "action", align: "center" },
  ];

  const handlePerformanceDrawerOpen = (playerId) => {
    setSelectedPlayer(playerId);
    setPerformanceDrawerOpen(true);
  };

  const handlePerformanceDrawerClose = () => {
    setPerformanceDrawerOpen(false);
  };

  useEffect(async () => {
    const response = await SoccerApi.getSquad();
    const squad = response.data;
    setPlayers(
      squad.map((player) => {
        return {
          player: (
            <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
              <SuiBox mr={2}>
                <SuiAvatar
                  src={`https://images.fotmob.com/image_resources/playerimages/${player.id}.png`}
                  alt={player.name}
                  size="sm"
                  variant="rounded"
                />
              </SuiBox>
              <SuiBox display="flex" flexDirection="column">
                <SuiTypography variant="button" fontWeight="medium">
                  {player.name}
                </SuiTypography>
                <SuiTypography variant="caption" textColor="secondary">
                  # {player.shirtNumber}
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          ),
          position: (
            <SuiBox display="flex" flexDirection="column">
              <SuiTypography variant="caption" fontWeight="medium" textColor="text">
                {player.role.fallback}
              </SuiTypography>
            </SuiBox>
          ),
          country: (
            <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
              {player.cname}
            </SuiTypography>
          ),
          goals: (
            <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
              {player.goals}
            </SuiTypography>
          ),
          assists: (
            <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
              {player.assists}
            </SuiTypography>
          ),
          rating: player.rating ? (
            <SuiBadge
              variant="gradient"
              badgeContent={player.rating}
              color={player.rating > 7 ? "success" : "warning"}
              size="extra-small"
            />
          ) : (
            "N/A"
          ),
          action: (
            <>
              {player.rating ? (
                <Link
                  style={{ color: "rgb(52, 71, 103)" }}
                  onClick={() => handlePerformanceDrawerOpen(player)}
                >
                  View traits
                </Link>
              ) : null}
            </>
          ),
        };
      })
    );
  }, []);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {performanceDrawerOpen ? (
        <div>
          <PerformanceDrawer
            open={performanceDrawerOpen}
            playerId={selectedPlayer.id}
            playerName={selectedPlayer.name}
            playerShirtNumber={selectedPlayer.shirtNumber}
            handleClose={handlePerformanceDrawerClose}
          />
        </div>
      ) : null}
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Current Players</SuiTypography>
            </SuiBox>
            <SuiBox customClass={classes.tables_table}>
              {players ? (
                <Table columns={playerColumns} rows={players} />
              ) : (
                <div style={{ display: "flex", justifyContent: "center", paddingBottom: "16px" }}>
                  <CircularProgress style={{ color: "rgb(52, 71, 103)" }} />
                </div>
              )}
            </SuiBox>
          </Card>
        </SuiBox>
        {/* <Card>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SuiTypography variant="h6">Projects table</SuiTypography>
          </SuiBox>
          <SuiBox customClass={classes.tables_table}>
            <Table columns={prCols} rows={prRows} />
          </SuiBox>
        </Card> */}
      </SuiBox>
    </DashboardLayout>
  );
}

export default Tables;
