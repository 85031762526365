import PropTypes from "prop-types";
import SoccerApi from "api/soccer";
import { useEffect, useState } from "react";
const { Drawer } = require("@mui/material");
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import { CircularProgress } from "@mui/material";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

function PerformanceDrawer(props) {
  const [traits, setTraits] = useState(null);
  const [requestCompleted, setRequestCompleted] = useState(false);

  useEffect(async () => {
    const response = await SoccerApi.getPlayerTraits(props.playerId);
    setTraits(response.data);
    setRequestCompleted(true);
  }, []);
  return (
    <Drawer
      sx={{ "& .MuiPaper-root": { width: "50%" } }}
      open={props.open}
      anchor="right"
      onClose={props.handleClose}
    >
      <SuiBox display="flex" flexDirection="column">
        <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
          <SuiBox mr={2}>
            <SuiAvatar
              src={`https://images.fotmob.com/image_resources/playerimages/${props.playerId}.png`}
              alt={props.playerName}
              size="sm"
              variant="rounded"
            />
          </SuiBox>
          <SuiBox display="flex" flexDirection="column">
            <SuiTypography variant="button" fontWeight="medium">
              {props.playerName}
            </SuiTypography>
            <SuiTypography variant="caption" textColor="secondary">
              # {props.playerShirtNumber}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        {traits ? (
          <div className="flex" style={{ height: "50%" }}>
            <Radar
              style={{ padding: "10px" }}
              data={{
                labels: traits.items.map(
                  (item) => `${Math.round(item.value * 100)}% ${item.title}`
                ),
                datasets: [
                  {
                    label: traits.title,
                    data: traits.items.map((item) => Math.round(item.value * 100)),
                    backgroundColor: "rgba(127, 145, 181, 0.2)",
                    borderColor: "rgba(127, 145, 181)",
                    borderWidth: 1,
                  },
                ],
              }}
              options={{
                scale: {
                  min: 0,
                  max: 100,
                },
                // maintainAspectRatio: false,
              }}
            />
          </div>
        ) : requestCompleted ? (
          <div style={{ alignSelf: "center", padding: "15px" }}>Player traits not available.</div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center", paddingBottom: "16px" }}>
            <CircularProgress style={{ color: "rgb(52, 71, 103)" }} />
          </div>
        )}
      </SuiBox>
    </Drawer>
  );
}

PerformanceDrawer.propTypes = {
  playerId: PropTypes.string,
  playerName: PropTypes.string,
  playerShirtNumber: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default PerformanceDrawer;
